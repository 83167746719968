@import "https://cdn.jsdelivr.net/gh/tonsky/FiraCode@1.207/distr/fira_code.css";
code, pre {
  font-family: Fira Code, Courier New, monospace;
}

@supports (font-variation-settings: normal) {
  code, pre {
    font-family: Fira Code VF, Courier New, monospace;
  }
}

:root {
  --wrap-background-color: #261e31;
  --wrap-accent-color: #48395e;
  --copy-background-color: #dfdae7;
  --text-color: #222;
  --header-color: var(--wrap-accent-color);
  --header-font-family: "Noticia Text", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  --copy-font-family: "Ibarra Real Nova", "Roboto Slab", "Times", serif;
}

@media (prefers-color-scheme: dark) {
  :root {
    --wrap-background-color: #111;
    --text-color: #dfdae7;
    --wrap-accent-color: #c191d7;
    --copy-background-color: var(--wrap-background-color);
  }
}

html {
  background-color: var(--wrap-background-color);
}

body {
  max-width: 92ch;
  min-height: 100vh;
  grid-template: ". header ." 10ch
                 ". main ." 1fr
                 ". footer ." 5ch
                 / 1px auto 1px;
  align-items: center;
  margin: auto;
  display: grid;
}

h1, h2, h3, h4, h5, h6, a {
  color: var(--header-color);
  font-family: var(--header-font-family);
}

p, span, li {
  color: var(--text-color);
}

.wrap {
  background-color: var(--wrap-background-color);
  grid-area: wrap;
}

header, main, footer {
  background-color: var(--copy-background-color);
  font-family: var(--copy-font-family);
  height: 100%;
}

header {
  flex-direction: row;
  grid-area: header;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

header > hgroup, header > nav {
  margin: 0 1ch;
  display: inline-block;
}

header > nav {
  display: inline-block;
}

header > nav ul li {
  display: inline;
}

header > nav ul li:not(:first-child):before {
  content: " | ";
}

main {
  grid-area: main;
  padding-inline: 5ch;
}

aside {
  max-width: 60ch;
  margin: auto;
}

img {
  width: 100%;
  object-fit: cover;
}

footer {
  justify-content: end;
  font-size: smaller;
  display: flex;
}

hr.blog-divider {
  border: none;
  border-top: medium double var(--wrap-accent-color);
  color: var(--wrap-accent-color);
  text-align: center;
  width: 90%;
  margin-top: 10ch;
  padding: 0;
  overflow: visible;
}

hr.blog-divider:after {
  content: "📰";
  background: var(--copy-background-color);
  background-color: var(--copy-background-color);
  padding: 0 .5em;
  font-size: 1.5em;
  line-height: 1.5em;
  display: inline-block;
  position: relative;
  top: -.75em;
}

hr.post-divider {
  width: 50%;
  background: linear-gradient( to right, transparent, var(--wrap-accent-color), transparent );
  border: 0;
  margin-block: 3ch;
  padding-top: 1px;
}

article {
  max-width: 80ch;
  margin: auto;
  padding-block: 1ch;
}

article div.article-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

article div.article-header span {
  display: inline-block;
}

article p {
  font-family: var(--copy-font-family);
  font-size: 16px;
  font-weight: 300;
}

article.collapsed div.article-body > p:nth-child(n+2) {
  display: none;
}

article div.article-header h2.expanded ~ div.article-body > p:nth-child(n+2) {
  display: block;
}

@keyframes wiggle {
  0% {
    transform: rotate(0);
  }

  80% {
    transform: rotate(0);
  }

  85% {
    transform: rotate(5deg);
  }

  95% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0);
  }
}

article a.article-expander {
  animation: 2s infinite wiggle;
  display: inline-block;
}

article a.article-expander:hover {
  animation: none;
}

.article-title {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.article-title * {
  display: inline-block;
}

.article-title h2:hover:after {
  content: " 🔗";
  font-size: smaller;
}

footer {
  text-align: center;
  grid-area: footer;
}

.columns-2 {
  columns: 2;
}

.columns-2 > p:first-of-type {
  margin-top: 0;
}

.indicator {
  cursor: pointer;
  animation: 2s infinite wiggle;
  display: inline-block;
}

@media (prefers-reduced-motion) {
  .indicator, article a.article-expander {
    animation: none;
  }
}

.image-pop {
  background-color: var(--wrap-background-color);
  color: var(--text-color);
  border: 1px solid var(--wrap-accent-color);
  border-radius: .5em;
  margin-inline: 1rem;
}

.image-pop img {
  filter: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  max-height: 90vh;
}

.image-pop .image-pop-alt {
  text-align: center;
  font-size: .875rem;
  font-style: italic;
  line-height: 1.25rem;
}

.closer {
  z-index: 10;
  opacity: 50;
  filter: contrast(1.5);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 2.25rem;
  line-height: 2.5rem;
  position: absolute;
  right: 1.5rem;
}

@media screen and (max-width: 90ch) {
  body {
    max-width: max-content;
    grid-row-gap: 1ch;
    grid-template: "header" 10ch
                   "main" 1fr
                   "footer" 5ch
                   / auto;
    align-items: center;
    padding: 0 1ch;
    display: grid;
  }

  main {
    padding: 0 1ch;
  }
}

@media screen and (max-width: 450px) {
  body {
    padding: 0;
  }

  header > nav ul li {
    display: block;
  }

  header > nav ul li:not(:first-child):before {
    content: "";
  }

  .columns-2 {
    columns: 1;
  }
}

/*# sourceMappingURL=index.48c8f250.css.map */
